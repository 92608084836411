<template>
  <v-menu bottom offset-y close-on-content-click>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" class="mr-1">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </template>

    <v-list width="300px">
      <ProfileDialog />
      <v-list-item >
        <v-list-item-icon>
          <v-icon>mdi-cog-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Settings</v-list-item-title>
      </v-list-item>
      <ChangePasswordDialog />
      <v-divider></v-divider>
      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

// TODO: lazy load component
import ProfileDialog from '../views/auth/ProfileDialog.vue'
import ChangePasswordDialog from '../views/auth/ChangePasswordDialog.vue'

export default {
  name: 'TheHeaderDropdownAccnt',
  components: {
    ProfileDialog, ChangePasswordDialog
  },
  methods:{
    logout(event){
      this.$cookies.remove("requestToken");                        
      this.$cookies.remove("renewToken");
      setTimeout(() => this.$router.push('/login'), 1500);      
    },
  }
}
</script>