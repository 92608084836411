<template>
  <div
    class="d-flex justify-space-between align-center"
    :class="inset ? 'px-4' : ''"
    style="min-height: 64px"
  >
    <div class="d-flex align-center">
      <div v-if="$slots.default" class="text-subtitle-1 font-weight-bold mr-4">
        <slot />
      </div>
      <slot name="left-items" />
    </div>
    <div class="d-flex align-center">
      <slot name="right-items" />
      <v-btn
        v-if="$listeners.close"
        small
        icon
        class="ml-4"
        @click="$emit('close')"
      >
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inset: {
      type: Boolean,
      default: true
    }
  }
}
</script>