<!--
TODO: Only work with required
TODO: autocomplete
-->

<template>
  <div>
    <label v-if="label" :class="labelClass">{{ label }}</label>
    <v-text-field
      v-model="_value"
      :placeholder="placeholder"
      :disabled="disabled"
      :dense="true"
      :type="showText ? 'text' : 'password'"
      :hide-details="dense ? 'auto' : false"
      :error-messages="_errorMessage()"
      class="mt-0"
      :solo="filled"
      :flat="filled"
      :background-color="backgroundColor"
      @focus="focus"
      @blur="blur"
    >
      <template #append>
        <v-btn icon small @click="showText = !showText" tabindex="-1">
          <v-icon small v-text="showText ? 'mdi-eye-off' : 'mdi-eye'"></v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {

  data() {
    return {
      onFocus: false,
      showText: false,
      isVuelidateModel: typeof(this.value) === 'object' && this.value !== null && this.value.hasOwnProperty('$model')
    }
  },
  
  props: {    
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    'error-messages': {
      type: String,
      default: undefined
    },
    filled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String
    },
    value: {
      required: true
    },
  },

  computed: {
    labelClass() {
      return this.onFocus ? 'primary--text' : 'grey--text text--darken-2'
    },
    backgroundColor() {
      return this.filled ? 'grey lighten-4' : null
    },
    _value: {
      get() {
        return this.isVuelidateModel ? this.value.$model : this.value
      },
      set(newValue) {
        if (this.isVuelidateModel) {
          this.value.$model = newValue
        } else {
          this.$emit('input', newValue)
        }
      }
    }
  },

  methods: {
    
    focus() {
      this.onFocus = true
    },

    blur() {
      this.onFocus = false
    },

    _errorMessage() {
      if (this.errorMessages != null) {
        return this.errorMessages
      }
      
      if (!this.isVuelidateModel) { return null }
      
      if (!this.value.$dirty) { return null }

      if (this.value.required === false) {
        return 'Required'
      }

      return null
    }
  }
}
</script>

<style scoped>
label {
  font-size: 10px;
}
</style>