<template>
  <button
    class="text-caption text-uppercase black--text font-weight-bold"
    @click="click"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  
  props: {
    to: {
      type: [String, Object],
      default: undefined
    }
  },

  methods: {
    click() {
      if (this.to) {
        this.$router.push(this.to)
      } else {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped>
button {
  border-bottom: 2px solid black;
  height: 22px;
}
</style>