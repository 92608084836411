export default [
  {
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer'
  },
  {
    name: 'Connection',
    to: '/connection',
    icon: 'cil-swap-horizontal'
  },
  {
    name: 'Form',
    to: '/form',
    icon: 'cil-notes'
  },
  {
    name: 'Environment',
    to: '/environment',
    icon: 'cil-notes'
  },
  {
    name: 'Listener',
    to: '/listener',
    icon: 'cil-notes'
  },
  {
    name: 'Flow',
    to: '/flow',
    icon: 'cil-notes'
  },
  {
    name: 'API Key',
    to: '/apikey',
    icon: 'mdi-key'
  }
]
