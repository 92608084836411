<!--========================
Author by: Sreng Veasna
Created on: 04.09.2020
Modified:
Company: F2C V2
Description:
=========================-->
<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attr }">
      <v-list-item v-bind="attr" v-on="on">
        <v-list-item-icon>
          <v-icon>mdi-lock-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Change Password</v-list-item-title>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title class="py-6">Change Password</v-card-title>

      <v-card-text>
        <v-alert
          v-show="isShowAlert"
          text
          :type="isSuccess ? 'success' : 'error'"
          transition="fade-transition"
          >{{ alertmessage }}</v-alert
        >

        <v-form>
          <v-row>
            <v-col cols="12">
              <SecretTextField
                label="OLD PASSWORD"
                v-model="$v.fchangepwd.old_password"
                :error-messages="errorMessage('old_password')"
              />
            </v-col>

            <v-col cols="12">
              <SecretTextField
                label="NEW PASSWORD"
                v-model="$v.fchangepwd.new_password"
                :error-messages="errorMessage('new_password')"
              />
            </v-col>

            <v-col cols="12">
              <SecretTextField
                label="CONFIRM NEW PASSWORD"
                v-model="$v.fchangepwd.confirmnewpassword"
                :error-messages="errorMessage('confirmnewpassword')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <DismissButton @click="dialog = false">Cancel</DismissButton>
        <PrimaryButton @click="submitChangePwd">Submit</PrimaryButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: "ChangePwd",
  data: function () {
    return {
      dialog: false,
      fchangepwd: this.getEmptyFormChangePwd(),
      isShowAlert: false,
      alertmessage: "",
      isSuccess: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    fchangepwd: {
      old_password: {
        required,
      },
      new_password: {
        required,
        minLength: minLength(8),
        validate: (value) => {
          var strongRegex = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
          );
          return strongRegex.test(value);
        },
      },
      confirmnewpassword: {
        required,
        sameAsPassword: sameAs("new_password"),
      },
    },
  },
  methods: {
    clearDataFormChangePwd: function () {
      this.fchangepwd = this.getEmptyFormChangePwd();
      this.$v.$reset();
    },
    submitChangePwd: async function (event) {
      //await this.$helpers.refreshAccessToken();
      if (this.$v.fchangepwd.$invalid) {
        this.$v.fchangepwd.$touch();
        event.preventDefault();
      } else {
        this.$store.commit("set", ["modalSpinnerText", "Saving..."]);
        this.$store.commit("toggle", "showHideModalSpinner");
        event.preventDefault();
        this.isShowAlert = false;
        this.alertmessage = "";
        var This = this;
        let configHeader = {
          headers: {
            Authorization: "Bearer " + this.$cookies.get("requestToken"),
          },
        };
        let data = {
          old_password: this.fchangepwd["old_password"],
          new_password: this.fchangepwd["new_password"],
        };
        this.$http
          .post("/api/user/changePassword", data, configHeader)
          .then(function (response) {
            //console.log("Log change password successfully:");
            This.$store.commit("toggle", "showHideModalSpinner");
            event.preventDefault();
            This.isSuccess = true;
            This.isShowAlert = true;
            This.clearDataFormChangePwd();
            This.alertmessage = "The change password successfully.";
          })
          .catch(function (error) {
            This.$store.commit("toggle", "showHideModalSpinner");
            //console.log("Log change password error:");
            console.log(error.response);
            if (error.response) {
              This.isSuccess = false;
              This.isShowAlert = true;
              This.alertmessage = error.response.data.message;
            }
          });
      }
    },
    getEmptyFormChangePwd() {
      return {
        new_password: "",
        old_password: "",
        confirmnewpassword: "",
      };
    },
    errorMessage(fieldName) {
      const field = this.$v.fchangepwd[fieldName];
      if (!field.$dirty) { return null; }

      if (fieldName == "old_password") {
        return !field.required ? "You need to input old password" : null;
      }

      if (fieldName == "new_password") {
        return !field.required ? "You need to input new password" :
          (!field.validate ? 'New password must be at least 8 characters containing at least an uppercase letter and a number' : null);
      }

      if (fieldName == 'confirmnewpassword') {
        return !field.required ? 'You need to confirm the new password' :
          (!field.sameAsPassword ? 'New password must match' : null)
      }

      return null;
    },
  },
};
</script>
