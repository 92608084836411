<template>
  <v-navigation-drawer app dark permanent>
    <v-list-item class='px-8' to="/">
      <v-list-item-content>
        <v-list-item-icon class='my-0 '>
          <v-img height="40px" contain src="../assets/logos/wiresk-full-reversed.png"></v-img>
        </v-list-item-icon>
      </v-list-item-content>
    </v-list-item>

    <v-divider class='mt-0'></v-divider>

    <v-list dense nav>
      <v-list-item v-for="navigation in $options.nav" :key="navigation.name" :to="navigation.to">
        <!-- <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon> -->

        <v-list-item-content>
          <v-list-item-title>{{ navigation.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import nav from "./_nav";
export default {
  name: "TheSidebar",
  nav,
}
</script>
