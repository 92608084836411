import Vue from 'vue'

import TitleBar from './Bars/TitleBar.vue'
import SectionBar from './Bars/SectionBar.vue'

import InputTextField from './TextFields/InputTextField.vue'
import SecretTextField from './TextFields/SecretTextField.vue'
import SelectTextField from './TextFields/SelectTextField.vue'
import SearchTextField from './TextFields/SearchTextField.vue'

import PrimaryButton from './Buttons/PrimaryButton.vue'
import SecondaryButton from './Buttons/SecondaryButton.vue'
import DismissButton from './Buttons/DismissButton.vue'
import NavigationButton from './Buttons/NavigationButton.vue'

Vue.component('TitleBar', TitleBar)
Vue.component('SectionBar', SectionBar)

Vue.component('InputTextField', InputTextField)
Vue.component('SecretTextField', SecretTextField)
Vue.component('SelectTextField', SelectTextField)
Vue.component('SearchTextField', SearchTextField)

Vue.component('PrimaryButton', PrimaryButton)
Vue.component('SecondaryButton', SecondaryButton)
Vue.component('DismissButton', DismissButton)
Vue.component('NavigationButton', NavigationButton)