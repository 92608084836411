
<!--
TODO: Only work with required, email
TODO: autocomplete
-->

<template>
  <div>
    <label v-if="label" :class="labelClass">{{ label }}</label>
    <v-text-field
      v-model="_value"
      :placeholder="placeholder"
      :disabled="disabled"
      :dense="true"
      :hide-details="dense ? 'auto' : false"
      :error-messages="errorMessage()"
      class="mt-0"
      :solo="filled"
      :flat="filled"
      :background-color="backgroundColor"
      @focus="focus"
      @blur="blur"
    />
  </div>
</template>

<script>
export default {

  data() {
    return {
      onFocus: false,
      isVuelidateModel: typeof(this.value) === 'object' && this.value !== null && this.value.hasOwnProperty('$model')
    }
  },
  
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String
    },
    value: {
      required: true
    },
  },

  computed: {
    labelClass() {
      return this.onFocus ? 'primary--text' : 'grey--text text--darken-2'
    },
    backgroundColor() {
      return this.filled ? 'grey lighten-4' : null
    },
    _value: {
      get() {
        return this.isVuelidateModel ? this.value.$model : this.value
      },
      set(newValue) {
        if (this.isVuelidateModel) {
          this.value.$model = newValue
        } else {
          this.$emit('input', newValue)
        }
      }
    }
  },

  methods: {

    focus() {
      this.onFocus = true
    },

    blur() {
      this.onFocus = false
    },

    errorMessage() {
      
      if (!this.isVuelidateModel) { return null }

      if (!this.value.$dirty) { return null }

      if (this.value.required === false) {
        return 'Required'
      }
      if (this.value.email === false) {
        return "Must be a valid email address"
      }

      return null
    }
  }
}
</script>

<style scoped>
label {
  font-size: 10px;
}
</style>