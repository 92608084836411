<template>
  <div
    class="d-flex justify-space-between align-center"
    :class="inset ? 'px-4' : ''"
    style="min-height: 50px"
  >
    <div class="d-flex align-center">
      <div v-if="$slots.default" class="text-caption font-weight-bold mr-4">
        <slot />
      </div>
      <slot name="left-items" />
    </div>
    <div class="d-flex align-center">
      <slot name="right-items" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inset: {
      type: Boolean,
      default: true
    }
  }
}
</script>