<template>
  <v-text-field
    class="rounded-lg"
    prepend-inner-icon="mdi-magnify"
    background-color="grey lighten-3"
    solo
    flat
    dense
    placeholder="Search"
    hide-details
    :value="value" 
    @input="input"
  >
  </v-text-field>
</template>

<script>
export default {
  props: ['value'],
  methods: {
    input(value) {
      this.$emit('input', value)
    }
  }
};
</script>