<template>
  <div>
    <TheSidebar />
    <TheHeader />

    <v-main>
      <router-view></router-view>
    </v-main>

    <TheFooter />
    <SpinnerOverlay />
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";
import SpinnerOverlay from "../components/SpinnerOverlay";

export default {
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    SpinnerOverlay,
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
