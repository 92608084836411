<template>
  <div>
    <v-card class="ma-4" flat>
      <TitleBar>
        Dashboard
        <template #right-items>
          <PrimaryButton @click="warningModal = true">Add New</PrimaryButton>
        </template>
      </TitleBar>
      <SectionBar>
        <template #left-items>
          <SearchTextField v-model="search" />
        </template>
      </SectionBar>

      <v-data-table :headers="headers" :items="forms" :search="search">
        <!-- eslint-disable-next-line -->
        <template #item.active="{ item }">
          <v-switch v-model="item.active"></v-switch>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog width="600px" v-model="warningModal">
      <v-card>
        <v-card-title
          >How would you like to create your new form ?</v-card-title
        >
        <div class="modal_body">
          <div class="box" @click="gotoFormBuilder()">
            <img src="../assets/icons/drag_drop_formbuilder.png" />
            <label>Drag and Drop Form Builder</label>
          </div>
          <div class="box">
            <img src="../assets/icons/drag_drop_formbuilder.png" />
            <label>Drag and Drop Form Builder</label>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped src="@/css/dashboard.css"></style>

<script>

export default {

  name: 'Dashboard',
  data: function () {
    return {
      search: "",
      warningModal: false,
      forms: [],
      headers: [
        { value: 'active', text: 'Status', width: "100px", sortable: false, filterable: false },
        { value: 'name', text: 'Name' }
      ]
    }
  },
  mounted(){
    this.getDashboardData();
  },
  methods: {
    async getDashboardData () {
      const headers = {
        Authorization: 'Bearer ' + this.$cookies.get("requestToken")
      }
      try {
        const response = await this.$http.get('/api/form', { headers })
        .then((response)=>{
          return response;
        }).catch((error)=>{
          console.error(error)
          return {data:[]}
        })
        this.forms = response.data
      } catch (error) {
        console.log(error)
      }
    },
    gotoFormBuilder: function(){
      window.location.href = '/login';
    }
  }
}
</script>
