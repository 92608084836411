<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
  body{
    background-color: white;
  }
</style>
<style src="../node_modules/drawflow/dist/drawflow.min.css"></style>
<style src="@/css/main.css"></style>
