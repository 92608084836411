<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-icon>
          <v-icon>mdi-account-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Profile</v-list-item-title>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title class='py-6'>Profile</v-card-title>

      <v-card-text>

        <v-alert
          v-show="isShowAlert"
          text
          :type="isSuccess ? 'success' : 'error'"
          transition="fade-transition"
          >{{ alertmessage }}</v-alert
        >

        <v-form>
          <v-row>
            <v-col cols="12" sm='6' class='py-0'>
              <InputTextField label="FIRST NAME" v-model="$v.profile.first_name"/>
            </v-col>
            <v-col cols="12" sm='6' class='py-0'>
              <InputTextField label="LAST NAME" v-model="$v.profile.last_name" />
            </v-col>

            <v-col cols="12" class='py-0'>
              <InputTextField label='COMPANY' v-model="$v.profile.company" />
            </v-col>

            <v-col cols="12" class='py-0'>
              <InputTextField label='EMAIL' v-model="$v.profile.email" />
            </v-col>

            <v-col cols="12" sm='6' class='py-0'>
              <InputTextField label="JOB COUNT TOTAL" :value='profile.job_count_total' filled disabled/>
            </v-col>
            <v-col cols="12" sm='6' class='py-0'>
              <InputTextField label="JOB COUNT MONTHLY" :value='profile.job_count_monthly' filled disabled/>
            </v-col>

            <v-col cols="12" sm='6' class='py-0'>
              <InputTextField label="JOB LIMIT MONTHLY" :value='profile.job_limit_monthly' filled disabled/>
            </v-col>
            <v-col cols="12" sm='6' class='py-0'>
              <InputTextField label="CREATED ON" :value='profile.createdon' filled disabled/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text>
        <v-divider></v-divider>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="dialog = false" text>Discard</v-btn>
        <v-btn @click="submitProfile" color="success" :disabled="$v.profile.$invalid">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {

  data() {
    return {
      dialog: null,
      profile: this.nullProfile(),
      isShowAlert: false,
      alertmessage: "",
      isSuccess: false,
    };
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.getProfile()
      } else {
        this.profile = this.nullProfile()
        this.$v.$reset()
      }
    }
  },

  mixins: [validationMixin],
  validations: {
    profile: {
      first_name: { required },
      last_name: { required },
      company: { required },
      email: { required, email },
    },
  },

  methods: {
    async getProfile() {
      // TODO: some kind of loader
      //await this.$helpers.refreshAccessToken();
      const headers = {
        Authorization: "Bearer " + window.$cookies.get("requestToken")
      }

      try {
        const response = await this.$http.get('/api/user/profile', { headers })
        this.profile = response.data
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          alert('Message error: '+ error.response.data.message);
        }
      }
    },

    async submitProfile() {
      //await this.$helpers.refreshAccessToken();
      // TODO: some kind of loader
      this.isShowAlert = false
      this.alertmessage = null

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken")
      }
      let data = {
        first_name: this.profile.first_name,
        last_name: this.profile.last_name,
        company: this.profile.company,
        email: this.profile.email
      }

      try {
        this.$http.patch('/api/user/' + this.profile.iduser, data, { headers })
        this.isSuccess = true
        this.isShowAlert = false
        this.dialog = false
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          this.isSuccess = false;
          this.isShowAlert = true;
          this.alertmessage = error.response.data.message;
        }
      }

    },
    nullProfile() {
      return {
        iduser: null,
        first_name: null,
        last_name: null,
        company: null,
        email: null,
        job_count_total: null,
        job_count_monthly: null,
        job_limit_monthly: null,
        createdon: null,
      }
    },
    errorMessage(fieldName) {
      if (!this.$v.profile[fieldName].$dirty) { return null }

      if (!this.$v.profile[fieldName].required) {
        return "Required field"
      }

      if (fieldName == 'email' && !this.$v.profile[fieldName].email) {
        return "Must be a valid email"
      }

      return null
    },
  },
};
</script>
