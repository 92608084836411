import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  //Spinner
  showHideModalSpinner: false,
  modalSpinnerText: "Loading...",

}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  },
}

export default new Vuex.Store({
  state,
  mutations
})
