import Axios from 'axios'

export default {
    refreshAccessToken: async function() {
        var tokenResponse=window.$cookies.get("requestToken");
        if(tokenResponse==null){
          try {
            tokenResponse = await Axios.post('/api/user/renewToken',{
              renewToken: window.$cookies.get("renewToken")
            });
            if(tokenResponse != null){
              console.log('Log request new token successfully:');
              var timeRequestToken = tokenResponse.data["requestToken_Expiration"] * 1000;
              var datetimeRequestToken = new Date(timeRequestToken);
              window.$cookies.set("requestToken", tokenResponse.data["requestToken"], datetimeRequestToken);
            }
          } catch (err) {
            window.location.replace("/login");
          }
        }
        return tokenResponse;
    },
    convertNameToKey:function (name){
      //console.log("convertNameToKey ", name)
      if(!name || typeof name == "undefined" || name == null) return "";

      if( ['object','integer'].indexOf(typeof name) >= 0){
        name = name.toString()
      }

      // \w+ mean at least of one character that build word so it match every
      // word and function will be executed for every match
      return name.toString().replace(/\w+/g, function(txt) {
        // uppercase first letter and add rest unchanged
        return txt.charAt(0).toUpperCase() + txt.substr(1);
      }).replace(/\s/g, '');// remove any spaces
    },
    covertObjectToString:function (name){
      if(!name || typeof name == "undefined" || name == null) return "";

      if( ['object','integer'].indexOf(typeof name) >= 0){
        name = name.toString()
      }
      return name;
    },
  convertKeyToTitle:function (name){

      if(!name || typeof name == "undefined" || name == null) return "";

      if( ['object','integer'].indexOf(typeof name) >= 0){
        name = name.toString()
      }

      name = name.toString().replace(/(?=[A-Z])/,' ');
      const name_arr = name.split('_');
      for (let i =0; i < name_arr.length; i++){
        name_arr[i] = name_arr[i].charAt(0).toUpperCase() + name_arr[i].substr(1);
      }
      return name_arr.join(' ');
    }
}
