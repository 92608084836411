import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router/index'
import store from './store'
import Axios from 'axios'
import VueCookies from 'vue-cookies'
import helpers from './js/helpers'
import VueDragscroll from 'vue-dragscroll'
import vuetify from './plugins/vuetify'

import '@/components/BaseComponents'
//Axios.defaults.headers['Include-Form-String'] = 'true'
Vue.use(VueDragscroll)
Vue.prototype.$cookies = VueCookies;
Vue.prototype.$helpers = helpers;

Axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// login => login is required
// found => token was found and also valid
// other => something went wrong
const checkAndRenewToken = async()=>{
  if(VueCookies.get("requestToken") === null){

    if(VueCookies.get("renewToken") === null) return 'login';

    console.log("renew access token ....")
    const isRenewed = await Axios.post('/api/user/renewToken', {
      renewToken: VueCookies.get("renewToken")
    })
      .then((tokenResponse) => {
        console.log("token is response ", tokenResponse);
        var timeRequestToken = tokenResponse.data["requestToken_Expiration"] * 1000;
        var datetimeRequestToken = new Date(timeRequestToken);
        VueCookies.set("requestToken", tokenResponse.data["requestToken"], datetimeRequestToken);
        return 'found';
      })
      .catch((error)=>{
        //console.error("main.js [41] ",error)
        if(error.response && error.response.status === 401){
          return 'login'
        }
        return 'other';
      })
      return isRenewed;
  }
  return 'found';
}
const exception_apis = [
  "/api/user",
  "/api/user/login",
  "/api/user/resetPassword",
  "/api/user/newPassword",
  "/api/user/renewToken",
  "/api/user/resetPassword"
];
Axios.interceptors.request.use(async (config) => {
  console.log("request -> ",config)
  if(exception_apis.indexOf(config.url) >=0 ){
    return config;
  }
  const isRequiredToken = await checkAndRenewToken();

  //console.log('main.js [68] ',isRequiredToken)
  if( isRequiredToken === "found"){
    //console.log('main.js [69]',config)
    config.headers['Authorization'] = "Bearer " + VueCookies.get("requestToken")
    return config;
  }
  if( isRequiredToken === "login") {
    VueCookies.remove("requestToken")
    VueCookies.remove("renewToken")
    router.push({name: "Login"});
  }
  return config;
});

Axios.interceptors.response.use((response) => {
  // Return a successful response back to the calling service
  return response;
}, async (error) => {
  //console.log("main.js [84] ERROR ", error)
  if(error.response){
   // console.log("main.js [87] ERROR RESPONSE ", error.response)
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status === 401) {
      // Logout user if token refresh didn't work or user is disabled
      if (error.config.url === '/api/user/renewToken') {
        console.log('Cannot renew token, the issue are ',error)
        VueCookies.remove("requestToken")
        VueCookies.remove("renewToken")
        router.push({'name':'Login'})
      }else{
        // Try request again with new token
        console.log("renew token [retry] .... ");
        return await Axios.post('/api/user/renewToken', {
          renewToken: VueCookies.get("renewToken")
        })
          .then((tokenResponse) => {
            console.log("requested token was response ", tokenResponse);
            var timeRequestToken = tokenResponse.data["requestToken_Expiration"] * 1000;
            var datetimeRequestToken = new Date(timeRequestToken);
            VueCookies.set("requestToken", tokenResponse.data["requestToken"], datetimeRequestToken);

            console.log("retry request to api");
            const config = error.config;
            config.headers['Authorization'] = `Bearer ${tokenResponse.data["requestToken"]}`;
            return new Promise((resolve, reject) => {
              Axios.request(config).then(response => {
                resolve(response);
              }).catch((error) => {
                reject(error);
              })
            });
          })
          .catch((error) => {
            if (error.response.status === 401 || error.response.status === 404) {
              console.log('Cannot renew token, the issue are ',error)
              VueCookies.remove("requestToken")
              VueCookies.remove("renewToken")
              router.push({name: 'Login'});
            }
            return new Promise((resolve, reject) => {
              reject(error);
            });
          });
      }
    }
    else if(error.response.status === 404){
      console.log('Cannot renew token, the issue are ',error)
      VueCookies.remove("requestToken")
      VueCookies.remove("renewToken")
      router.push({'name':'Login'})
    }
    // -------------------------------------
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  return new Promise((resolve, reject) => {
    reject(error);
  });
});


// -------------------------------------------

Vue.prototype.$http = Axios;
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  vuetify,

  components: {
    App
  }
})
