import Vue from 'vue'
import Router from 'vue-router'
import Axios from 'axios'
import VueCookies from 'vue-cookies'

import AppContainer from '@/containers/AppContainer'
import Dashboard from '@/views/Dashboard'

Vue.use(Router)
const router = new Router({
  //mode: 'hash', // https://router.vuejs.org/api/#mode
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: AppContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'connection',
          name: 'List Connections',
          component: () => import('@/views/connection/index'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'form',
          name: 'Form',
          component: () => import('../views/form/index'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'form/edit/:idform',
          name: 'Form',
          component: () => import('../views/form/edit'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'form/view/:idform',
          name: 'Form',
          component: () => import('../views/form/view'),
          meta: {
            requiresAuth: true,
          }
        },
        // Environment
        {
          path: 'environment',
          name: 'Environment',
          component: () => import("@/views/environment/index"),
          meta: {
            requiresAuth: true
          }
        },
        // Listener
        {
          path: 'listener',
          name: 'Listener',
          component: () => import('@/views/listener/index'),
          meta: {
            requiresAuth: true
          }
        }, {
          path: 'listener/create',
          name: 'New Listener',
          component: () => import('@/views/listener/create'),
          meta: {
            requiresAuth: true
          }
        }, {
          path: 'listener/edit/:idlistener',
          name: 'Edit Listener',
          props: true,
          component: () => import('@/views/listener/edit'),
          meta: {
            requiresAuth: true
          }
        },
        // Flow
        {
          path: 'flow',
          name: 'Flow',
          component: () => import("@/views/flow/index"),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'flow/create',
          name: 'New Flow',
          component: () => import("@/views/flow/create"),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'flow/edit/:idform',
          name: 'Edit Flow',
          component: () => import("@/views/flow/edit"),
          meta: {
            requiresAuth: true,
          }
        },
        // API Key
        {
          path: 'apikey',
          name: "APIKey",
          component: () => import("@/views/apikey/index"),
          meta: {
            requiresAuth: true,
          }
        }
      ]
    },
    {
      path: '/auth',
      name: 'Auth',
      redirect: '/login',
      component: () => import('@/views/auth/AuthContainer'),
      children: [
        {
          path: '/login',
          name: 'Login',
          component: () => import('@/views/auth/LoginCard')
        },
        {
          path: '/register',
          name: 'Register',
          component: () => import('@/views/auth/RegisterCard')
        },
        {
          path: '/forgotpwd',
          name: 'ForgotPwd',
          component: () => import('@/views/auth/ForgotPasswordCard')
        }
      ]
    },
    {
      path: '/quote',
      name: 'QuoteDisplay',
      component: () => import('@/views/quote/QuoteDisplay'),
    },

  ]
});

router.beforeEach(async (to, from, next) => {

  // if user is already loged in and he try to acess login screen, we should redirect to `Home` screen
  if(to.name === "Login" || to.name === "ForgotPwd"){
    if (window.$cookies.get("renewToken") !== null || window.$cookies.get("renewToken")) {
      next({
        name: "Home"
      });
    }else{
      next();
    }
  }else{
    if (to.meta.requiresAuth) {
      // if `renewToken` is null, login is required
      if (VueCookies.get("renewToken") == null) {
        VueCookies.remove("renewToken")
        VueCookies.remove("requestToken")
        next({
          name: "Login"
        });
      } else {
        // but if `requestToken` is null, request requestToken from server by `renewToken`
        if (VueCookies.get("requestToken") == null) {
          console.log("Renew token is required")
          if(VueCookies.get("renewToken") == null){
            console.log("if `renewToken` is null, redirect Login screen")
            next({
              name: "Login"
            });
          }else{
            console.log("request to server `/api/user/renewToken`  to renew token (`requestToken`)")
            console.log("`renewToken` is ",VueCookies.get("renewToken"))
            await Axios.post('/api/user/renewToken', {
              renewToken: VueCookies.get("renewToken")
            })
              .then(function (response) {
                // Request is success saved your token to cookie
                console.log('Log request new token successfully:', response);
                if(response) {
                  var timeRequestToken = response.data["requestToken_Expiration"] * 1000;
                  var datetimeRequestToken = new Date(timeRequestToken);
                  VueCookies.set("requestToken", response.data["requestToken"], datetimeRequestToken);
                  next();
                }else{
                  console.log('`/api/user/renewToken` is ',response)
                }
              }).catch(function (error) {
              console.log('Cannot renew token, the issue are ',error)
              VueCookies.remove("renewToken")
              VueCookies.remove("requestToken")
              next({
                name: "Login"
              });
            });
          }
        } else {
          next();
        }
      }
    } else {
      next();
    }
  }
});

export default router;
