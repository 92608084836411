<template>
  <v-app-bar app>
    <v-app-bar-nav-icon></v-app-bar-nav-icon>

    <v-spacer></v-spacer>
    <the-header-dropdown-accnt />
  </v-app-bar>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },
};
</script>
