<template>
  <v-footer app inset>
    <div>
      <a :href="appSite" target="_blank">{{ appName }}</a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a :href="appAuthorSite">{{ appAuthor }}</a>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'TheFooter',
  props:{
    appName:{default:"Wiresk"},
    appAuthor:{default:"Wiresk"},
    appAuthorSite:{default:"https://wiresk.fun"},
    appSite:{default:"https://wiresk.fun"}
  }
}
</script>
